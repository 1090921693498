<template>
    <div class="backdrop">
        <div class="modal" :class="{sale: theme === 'sale'}">
            <h1>{{ header }}</h1>
            <p>{{ text }}</p>
        </div>
        <div class="semblance-photo">
            <div class="photo">
                <img src="../assets/20240218_000301.jpg" alt="David" width="250" height="300">
            </div>
            <div>
                <div v-for="(item, index) in items" :key="index">
                    <button @click="toggleDetails(index)">
                        {{ item.title }}
                    </button>
                    <div v-show="item.showDetails" class="additional-info">
                        <p>{{ item.description }}</p>
                        <div v-show="item.showArticles" class="additional-info-articles">
                            <li v-for="(item, index) in articulos" :key="index">
                              <a :href="item.url" target="_blank">{{ item.title }}</a> - {{ item.year }}
                            </li>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default{
    data() {
    return {
        items: [
        { title: "Semblance", description: "Hi! I'm David Herrera Sánchez, linving now in Xalapa, Veracruz . I'm a graduate of the Universidad Veracruzana in Electronic Instrumentation Engineering and a Master's degree in Artificial Intelligence, graduated with honorific mention. At this moment I'm a PhD student in Artificial Intelligence at Universidad Veracruzana. I ve participated in the National Computing Meeting (ENC) giving various tutorials. I obtained 2nd place in the RetoInnova-World in collaboration with the Metropolitan Technological Institute, Medellin, Colombia. My hobbies are Front-end and Backend practice. As well as learning new technologies in Artificial Intelligence. Areas of interest are computer vision, machine learning, and evolutionary computing. ", showDetails: false },
        { title: "Scientific Production", showDetails: false,showArticles: true },
        { title: "Contact", description: "davherrera@uv.mx", showDetails: false }
      ],
      articulos:[
                {title: 'Shifting Color Space for Image Classification using Genetic Programming', year:2024, conference:"GECCO 24'",url:"https://dl.acm.org/doi/10.1145/3638530.3654430", showArticles:true},
                {title: 'AutoML Based on Genetic Programming for Medical Image Classification', year:2023,conference:" MICAI - CIAPP",url:"https://link.springer.com/chapter/10.1007/978-3-031-51940-6_26",showArticles: true},
                {title: 'Feature Construction, Feature Reduction and Search Space Reduction Using Genetic Programming', year:2022, conference:"ISCMI 2022",url:"https://ieeexplore.ieee.org/abstract/document/10068452/", showArticles:true}
                
            ]
    };
  },
  methods: {
    toggleDetails(index) {
      this.items[index].showDetails = !this.items[index].showDetails;
    },
    toggleArticles(index) {
      this.articulos[index].showArticles = !this.items[index].showArticles;
    }
  },
    props:['header','text','theme']
}
</script>

<style scoped>

button {
  background-color:  rgb(41, 75, 88);
  color: white;
  width: auto;
  height: auto;
  margin: 5px;
  padding: 10px 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

button:hover {
  background-color: #5d637e;
}

.additional-info {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  margin-top: 5px;
}
.modal{
    font-style: normal;
    text-align: left;
    width: auto;
    padding: 20px;
    margin: 0 auto;
    background: white;
    /*border-radius: 10px;*/
}
.backdrop{
    top: 0;
    position: fixed;
    background: rgba(0, 0,0, 0.35);
    width: 100%;
    height: 100%;
}

.modal h1{
    color:azure;
    border: none;
    padding: 0;
}

.modal p{
    font-style: italic;
}
.modal.sale{
    background: rgb(41, 75, 88);
    color: white;
}
.semblance-photo{
    display: flex;
    flex-direction: column;
    align-content: flex;
}
</style>